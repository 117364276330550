import React, {
  useState,
  useEffect,
} from 'react';
import {
  useParams, useLocation
} from 'react-router-dom';
import styled from 'styled-components';
import {
  Box,
} from 'reflexbox';

import {
  isHandleAvailable,
  isSubHandleAvailable,
  getUserAddresses,
} from '../../utils/handleManager';
import { ChangeEvent } from 'react';
import { BigNumber, ethers } from 'ethers'
import toast, { Toaster } from 'react-hot-toast';

import crypto from 'crypto';

import Coins from '../../assets/coins/coins.json';

import {
  Card,
  Title,
  Text,
  NavLink,
  Row, Dropdown, Input, Modal,
  Flex,
  Error,
  Valid,

  Link,
  Button,
  ButtonText,
  Handle,
  Icon,
  Subtitle,
} from '../../style/components';
import TextField from '@mui/material/TextField';
import QRCode from "react-qr-code";
import { gtag, install } from 'ga-gtag';
import { deepOrange } from '@mui/material/colors';
import { responsiveFontSizes } from '@material-ui/core'; import WAV from 'multicoin-address-validator';

const AddressWrapper = styled(Text)`
    padding: 10px;
    border-color: #f7f7fa;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    opacity: 0.5;
    background-color: #f7f7fa;
  `;

interface AddAddressModalProps {
  onAddAddress: Function;
  toggle: Function;
  isOpen: boolean;
  isLoading: boolean;
}

function Pay() {
  let {
    handl,
    coin,
  }: any = useParams();

  const coins = Coins as CoinsInterface;
  let Wyre = '';
  const [hasCheckedHandle, setHasCheckedHandle] = useState('');
  const [handleExists, setHandleExists] = useState(false);
  const [uri, setUri] = useState('');
  const [address, setAddress] = useState('');
  const [Wyred, setW] = useState('');
  const [coi, setCoin] = useState(''); const [am, setam] = useState('');

  const [addres, setAddres] = useState('');
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let handle = params.get('name');

  let amt = params.get('amount');
  let amts;
  if (amt != null) {
    amts = ' ' + amt
  }
  else {
    amts = ''
  }


  let lol = params.get('LOL');
  const handleToCheck = params.get('name');
  const coinToCheck = params.get('token');;
  const Abi = [
    "function balanceOf() view returns (uint256)",
    "function decimals() view returns (uint256)",
    "function symbol() view returns (string)",
    "function transfer(address to, uint256 amount)",
  ];
  let amount = '.1';
  if (amt != null) {
    amount = amt
  }

  function AddAddressModal(props: AddAddressModalProps) {
    const {
      onAddAddress,
      toggle,
      isOpen,
      isLoading,
    } = props;

    const coins = Coins as CoinsInterface;

    const [coin, setCoin] = useState('');
    const [address, setAddress] = useState('');
    const [isValid, setValid] = useState(false);

    useEffect(() => {
      if (!isOpen) {
        setCoin('');
        setAddress('');
      }
    }, [isOpen]);

    useEffect(() => {
      if (coin !== '') {
        if (coins[coin].ERC20) {
          setValid(WAV.validate(address, 'ETH'));
        } else {
          setValid(WAV.validate(address, coin));
        }
      }
    }, [coin, coins, address]);

    return (
      <Modal
        isOpen={isOpen}
        toggle={() => toggle()}
        title="Add a new address"
      >
        <div>
          <Text noMargin small>
            Select a coin and enter the address you want to link to your handle.
          </Text>
        </div>
        <Flex padding="28px 0 14px 0">
          <Box width={2 / 3} px={1}>
            <Input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
              block
            />
          </Box>
          <Box width={1 / 3} px={1}>
            <Dropdown
              initialLabel="Coin"
              coins={Coins}
              onSelect={(e: string) => setCoin(e)}
              block
            />
          </Box>
        </Flex>
        <Row>
          {address !== '' && coin !== '' && (
            <>
              {isValid ? (
                <Valid small margin="0 0 12px 0">
                  This address is valid!
                </Valid>
              ) : (
                <Error small margin="0 0 12px 0">
                  This address is NOT valid!
                </Error>
              )}
            </>
          )}
        </Row>
        <Row>
          <Button
            genre="primary"
            onClick={() => setAddres(address)}
            disabled={coin === '' || address === '' || !isValid}
            isWaiting={isLoading}
            block
          >
            Confirm
          </Button>
        </Row>
      </Modal>
    );
  }
  function handleAmount(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const values = event.target.value;
    setam(values);
    console.log(am)
  }
  function handleAmt(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const values = event.target.value;
    amount = (values);
    console.log(am)
  }
  useEffect(() => {
    setAddress(params.get('address'));

    async function getAddresses() {
      const addresses = params.get('address');
      const rawUri = coins[coinToCheck.toUpperCase()].uri;
      setAddress(addresses)
      let formattedUri = rawUri.replace('{address}', addresses);
      if (amt != null) {
        if (coinToCheck.toUpperCase() == 'ETH') {
          if (amt.includes('.')) {
            amt = amt.replace('.', '')
            let len = amt.length
            amt = amt + '000000000000000000'
            amt = amt.substring(0, amt.length - len + 1);

            formattedUri = formattedUri + '?value=' + amt
          }
          else {
            formattedUri = formattedUri + '?value=' + amt + '000000000000000000'
          }
        }
        if (coins[coinToCheck.toUpperCase()].ERC20 == true) {
          if (coinToCheck.toUpperCase() == 'USDC') {
            if (amt.includes('.')) {
              amt = amt.replace('.', '')
              let len = amt.length
              amt = amt + '000000'
              amt = amt.substring(0, amt.length - len + 1);
              formattedUri = formattedUri + '&uint256=' + amt
            }
            else {
              formattedUri = formattedUri + '&uint256=' + amt + '000000'
            }
          }
          else {
            if (amt.includes('.')) {
              amt = amt.replace('.', '')
              amt = amt + '000000000000000000'

              let len = amt.length
              amt = amt.substring(0, amt.length - len + 1);

              formattedUri = formattedUri + '&uint256=' + amt
            }
            else { formattedUri = formattedUri + '&uint256=' + amt + '000000000000000000' }
          }
        }
        if (coinToCheck.toUpperCase() == 'BTC' || coinToCheck.toUpperCase() == 'BCH') {
          formattedUri = formattedUri + '?amount=' + amt
        }
      } console.log(formattedUri)
      setUri(formattedUri);
      gtag('event', 'open_addrs', {
        'addrs': 'custom',
        'coin': coinToCheck.toUpperCase(),
        'address': addresses

      });
      window.location.replace(formattedUri);
    }
    if (params.get('address') != null) {

      getAddresses()
    }
    async function getWyre() {
      let addrs = 'ethereum:' + params.get('address');
      ;
      let Url = 'https://buy-sandbox.moonpay.com?apiKey=pk_test_g4xs3id90JgBCX5B0kF46977ox6XZ1E1&currencyCode=' + coins[coinToCheck.toUpperCase()].ticker + '&walletAddress=' + address;
      let res = 'https://buy-sandbox.moonpay.com?apiKey=pk_test_g4xs3id90JgBCX5B0kF46977ox6XZ1E1&currencyCode=' + coins[coinToCheck.toUpperCase()].ticker + '&walletAddress=' + address;
      if (coins[coinToCheck.toUpperCase()].ticker == 'ETH') {
        addrs = 'ethereum:' + params.get('amount');
        console.log(await addrs)
      }
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer TEST-SK-TYJWJZ2J-H4AMJP9D-BBUTQY9Q-MBNENQP6'
        },
        body: JSON.stringify({
          destCurrency: coins[coinToCheck.toUpperCase()].ticker,
          dest: addrs,
          referrerAccountId: 'AC_P6FJDQF4WY7'
        })
      };
      let Wyred1;
      let resp = await fetch('https://api.testwyre.com/v3/orders/reserve', options)
        .then(response => response.json())

        .catch(err => console.error(err));
      console.log(resp.url)
      Wyre = (resp.url);
      console.log(Wyre)
      setW(Wyre)
    }
    // getWyre()



  }, [, coins, handleExists, coinToCheck]);

  function returnContent() {
    async function coinbase() {
      console.log(Wyred)
      let net = coins[coinToCheck.toUpperCase()].ERC20?'ethereum':coins[coinToCheck.toUpperCase()].name.toLowerCase()
      let lol: string = 'https://pay.coinbase.com/buy/select-asset?appId=58a3fa2e-617f-4198-81e7-096f5e498c00&addresses={"'+address+'":["'+net+'"]}'+'&assets=["'+coins[coinToCheck.toUpperCase()].ticker+'"]'
      if (amt != null) {
        lol = lol + '&presetCryptoAmount=' + Number(amt)
      }
      window.location.replace(lol)
    }
    async function wyre() {
      console.log(Wyred)
      let lol: string = 'https://global.transak.com?apiKey=46e5d2a3-6676-483e-82e4-ad542cf81fa2&defaultCryptoCurrency=' + coinToCheck.toUpperCase() + '&walletAddress=' + address
      if (amt != null) {
        lol = lol + '&defaultCryptoAmount=' + amt
      }
      window.location.replace(lol)
    }
    async function sendTokens() {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      let tokenaddrs = coins[coinToCheck.toUpperCase()].uri.slice(9, 51)
      const signer = provider.getSigner()
      let Contract = new ethers.Contract(tokenaddrs, Abi, signer);
      let myAddress = await signer.getAddress()
      let addresses = await params.get('address');
      setAddress(params.get('address'));

      let am;
      if ((await provider.getNetwork()).chainId != coins[coinToCheck.toUpperCase()].chain) {
        toast.error("Check network for wallet")
      }
      else {
        if (['ETH', 'MATIC', 'OPETH', 'ARBETH', 'ETHBASE'].includes(coins[coinToCheck.toUpperCase()].ticker)) {
          try {
            am = ethers.utils.parseEther(amount).toString()
            const tx = await signer.sendTransaction({
              to: addresses,
              value: am
            });
          }
          catch (err) {
            console.log(err)
            console.log(amount)
            console.log(am)
          }
        }

        else {
          try {
            let dec = await Contract.connect(signer).decimals()
            am = ethers.utils.parseUnits(amount, dec).toString()
            await Contract.connect(signer).transfer(addresses, am)////signer._address, sendMessage)
          }
          catch { }
        }
      }
    }

    async function check() {
      console.log(am)
      console.log(addres)
      if (addres == '') {
        console.log('lol')

        toast.error('No address'); return
      }
      if (hasCheckedHandle == '') { await setHasCheckedHandle('addrs') ;toast('Set name to addrs'); return }
      if (coi == '') { toast.error('No token'); return }


      if (coi !== '') {
        let s
        if (coins[coi].ERC20) {
          if (WAV.validate(addres, 'ETH')) {
            s = ('https://addrs.to/pay?token=' + coi + '&address=' + addres + '&name=' + hasCheckedHandle)
          }else {toast.error('Address not valid'); return}
        } else {
          if (WAV.validate(addres, coi)) {
            s = ('https://addrs.to/pay?token=' + coi + '&address=' + addres + '&name=' + hasCheckedHandle)
          }else {toast.error('Address not valid'); return}
        } console.log(am)
        if (am != '') {
          navigator.clipboard.writeText(s + '&amount=' + am)
        } else {
          navigator.clipboard.writeText(s)
        } toast.success('Copied payment request');
        gtag('event', 'new_req', {
          'addrs': 'addresses',
          'coin': coinToCheck.toUpperCase(),
          'amount': am
  
        });
        return

      }
    }async function chck() {
      console.log(am)
      console.log(addres)
      if (addres == '') {
        console.log('lol')

        toast.error('No address'); return
      }
      if (hasCheckedHandle == '') { await setHasCheckedHandle('addrs') ;toast('Set name to addrs'); return }
      if (coi == '') { toast.error('No token'); return }


      if (coi !== '') {
        let s
        if (coins[coi].ERC20) {
          if (WAV.validate(addres, 'ETH')) {
            s = ('https://addrs.to/pay?token=' + coi + '&address=' + addres + '&name=' + hasCheckedHandle)
          }else {toast.error('Address not valid'); return}
        } else {
          if (WAV.validate(addres, coi)) {
            s = ('https://addrs.to/pay?token=' + coi + '&address=' + addres + '&name=' + hasCheckedHandle)
          }else {toast.error('Address not valid'); return}
        } console.log(am)
        if (am != '') {
          window.location.replace(s + '&amount=' + am)
        } else {
          window.location.replace(s)
        } toast.success('Copied payment request');
        gtag('event', 'new_req', {
          'addrs': 'addresses',
          'coin': coinToCheck.toUpperCase(),
          'amount': am
  
        });
        return

      }
    }

    if (address == null || coinToCheck == null) {
      return (
        <>
          <Row margin="auto">
            <Handle margin="10px 0">
              Create a payment request
            </Handle>
            <Dropdown
              initialLabel="Select Token"
              coins={Coins}
              onSelect={(e: string) => setCoin(e)}
              block
            />              <Subtitle margin="10px 0">Your name</Subtitle>
            <Input
              value={hasCheckedHandle}
              onChange={(e) => setHasCheckedHandle(e.target.value)}
              placeholder="Enter Name"
              block   style={{ width: "50%" }}

            />              <Subtitle margin="10px 0">Amount</Subtitle>

              <Input style={{ width: "50%" }}
                id="outlined-basic"
                placeholder="Amount (optional)"
                onChange={(e) => handleAmount(e)}
              />
                     <Subtitle margin="10px 0">Your Address</Subtitle>

            <Input
              value={address}
              onChange={(e) => setAddres(e.target.value)}
              placeholder="Enter Address"
              block
            /><Row margin="10px 0">
              <Button genre="primary" onClick={check}>Copy Custom payment request</Button>
            </Row><Row margin="0px 0">
              <Button genre="primary" onClick={chck}>Open payment request</Button>
            </Row></Row>
        </>
      );
    }

    if (coins[coinToCheck.toUpperCase()].ERC20 == false && !['ETH', 'MATIC', 'OPETH', 'ARBETH', 'ETHBASE'].includes(coins[coinToCheck.toUpperCase()].ticker)) {
      return (
        <>
          <Row margin="auto">
            <Icon
              width="48px"
              src={coins[coinToCheck.toUpperCase()].logo}
              alt={coinToCheck.toUpperCase()}
            />
            <Title margin="10px 0">
              {`Sending${amts} ${coinToCheck.toUpperCase()}`}
            </Title>
            <Text margin="0 0 20px 0" small>
              You are about to send{amts} <b>{coins[coinToCheck.toUpperCase()].name}</b> to <b>{handle}</b>.
            </Text>
            <Handle margin="0">
              {handle}
            </Handle>
            <AddressWrapper margin="0 0 0.67em 0" small>
              {address}
            </AddressWrapper>
            {uri !== '' && (
              <>
                <Link
                  href={uri}
                  target="_blank"
                  rel="noreferrer noopener"
                  margin="0.67em"
                >
                  Click here to Open Wallet
                </Link>
              </>
            )}
            {['BTC', 'ETH', 'USDC', 'DAI', 'USDT', 'AAVE', 'BNB', 'BCH', 'MATIC', 'COMP', 'FTM', 'LINK', 'SOL', 'UNI', 'WBTC',].some((x) => x === coins[coinToCheck.toUpperCase()].ticker) && (
              <>
              <Button genre="primary" onClick={coinbase} style={{marginBottom:'6px'}}>
                <ButtonText>
                  Pay using Coinbase
                </ButtonText>
              </Button>
                <Button genre="primary" onClick={wyre}>
                  <ButtonText>
                    Pay using Visa
                  </ButtonText>
                </Button>

              </>



            )}<Card width="45" margin="20px 20px 20px">
              <QRCode value={uri} size={200} /></Card>
          </Row>
        </>
      );
    }
    return (
      <><div><Toaster toastOptions={{
        style: {
          fontFamily: 'HKGrotesk,sans-serif',
        },
      }} /></div>

        <Row margin="auto">
          <Icon
            width="48px"
            src={coins[coinToCheck.toUpperCase()].logo}
            alt={coinToCheck.toUpperCase()}
          />
          <Title margin="10px 0">
            {`Sending${amts} ${coinToCheck.toUpperCase()}`}
          </Title>
          <Text margin="0 0 20px 0" small>
            You are about to send{amts} <b>{coins[coinToCheck.toUpperCase()].name}</b> to <b>{handle}</b>.
          </Text>
          <Handle margin="0">
            {handle}
          </Handle>
          <AddressWrapper margin="0 0 0.67em 0" small>
            {address}
          </AddressWrapper>
          {uri !== '' && (
            <>
              <Box margin="0.67em 0 0.2em 0">
                <TextField id="outlined-basic" label="Amount" variant="outlined" onChange={e => handleAmt(e)} defaultValue={amount} />
              </Box>
              <Button genre="primary" onClick={sendTokens}>Send with Metamask</Button>

              <Link
                href={uri}
                target="_blank"
                rel="noreferrer noopener"
                margin="0.67em"
              >
                Click here to Open Wallet
              </Link>

            </>
          )}
          {['BTC', 'ETH', 'USDC', 'DAI', 'USDT', 'AAVE', 'BNB', 'BCH', 'MATIC', 'COMP', 'FTM', 'LINK', 'SOL', 'UNI', 'WBTC',].some((x) => x === coins[coinToCheck.toUpperCase()].ticker) && (
            <>
              <Button genre="primary" onClick={wyre}>
                <ButtonText>
                  Pay using Visa
                </ButtonText>
              </Button>

            </>)}<Card width="45" margin="20px 20px 20px">
            <QRCode value={uri} size={200} /></Card>
        </Row>
      </>
    );
  }
  return (
    <><Toaster />
      <Box width="100%" padding="32px" margin="auto">
        <Card width="100%" margin="auto">
          {returnContent()}
        </Card>
      </Box>
      <Row width="50%" margin="auto">
        <a href={`/pay`} target="_blank">
          <Button genre="primary">
            <ButtonText>
              Create a pay request
            </ButtonText>
          </Button>
        </a>
      </Row>
    </>
  );
}

export default Pay;
