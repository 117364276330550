import React, {
    useState, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import {
    Box,
} from 'reflexbox';
import axios from 'axios';
import {useWeb3React} from '@web3-react/core';
import {
    Title,
    Text,
    Row,
    Card,
    Button,
} from '../../style/components';

import SearchFormManager from '../searchForm/searchFormManager';
import {useWalletModalToggle} from '../WalletModal/state';

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCode from "react-qr-code";

import Grid from '@mui/material/Unstable_Grid2';

import Typography from '@mui/material/Typography';
//import WalletModal from '../WalletModal';

import LogInModal from '../logInModal';
import LostPasswordModal from '../lostPasswordModal';
import RegisterModalFull from '../registerModalFull';
import DuplicateContext from '../context/DuplicateContext';

import {
    logInUser,
    logInUserFromGoogle,
    resetPassword,
    registerUser,
    registerHandle,
    isHandleAvailable,
    logInUserCustom
} from '../../utils/handleManager';

import {
    returnErrorMessage,
} from '../../utils/errorHandler';
import WalletModal from "../WalletModal";

import {ethers} from "ethers"

import {APIADDRESS} from "../constants"
import * as firebase from "firebase";


const BigTitle = styled(Title)`
  font-size: 32px;
  color: #7db4ff
`;

const SubTitle = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;


function Home() {
    const [isLogInModalOpen, toggleLogInModal] = useState(false);
    const [logInError, setLogInError] = useState('');

    const [isRegisterModalOpen, toggleRegisterModal] = useState(false);
    const [registerError, setRegisterError] = useState('');

    const [isLostPasswordModalOpen, toggleLostPasswordModal] = useState(false);
    // @ts-ignore
    const [isduplicate, toggleisduplicate] = useContext(DuplicateContext);

    const {account, library} = useWeb3React();
    const toggleWalletModal = useWalletModalToggle();

    console.log(account);

    async function logInUserFromModal(email: string, password: string, setLogInError1: Function) {
        toggleisduplicate(false);
        try {
            await logInUser(email, password);
        } catch (e) {
            setLogInError1(returnErrorMessage(e.code));
        }
    }

    async function GoogleSignIn(setLogInError2: Function) {
        toggleisduplicate(false);
        try {
            await logInUserFromGoogle();
        } catch (e) {
            setLogInError2(returnErrorMessage(e.code));
        }
    }

    async function MetamaskLogin(setLogInError2: Function) {
        toggleisduplicate(false);

        if (account === "" || !account) {
            toggleWalletModal()
            return
        }

        try {
            let nonce = await axios.post(APIADDRESS + "/api/getnonce", {
                address: account
            })
            console.log(nonce)

            if (!nonce["data"]["NONCE"]) {
                setLogInError2("Nonce could not be retrieved")
            }

            // Sign the string message
            let signer = library.getSigner()
            let signature = await signer.signMessage(nonce["data"]["NONCE"])

            let resp = await axios.post(APIADDRESS + "/api/verify", {
                address: account,
                signature: signature
            })

            if (!resp["data"]["TOKEN"]) {
                setLogInError2("Token could not be retrieved")
            }

            await logInUserCustom(resp["data"]["TOKEN"]);

            //await logInUserFromGoogle();
            console.log("test")
        } catch (e) {
            console.log(e)
            setLogInError2(returnErrorMessage(e.code));
        }
    }


    async function MetamaskSignUp(handle: string, setRegisterError1: Function) {
        toggleisduplicate(false);

        if (account === "" || !account) {
            toggleWalletModal()
            return
        }

        const resavailable = await isHandleAvailable(handle);

        if (resavailable) {
            try {
                let nonce = await axios.post(APIADDRESS + "/api/getnonce", {
                    address: account
                })
                console.log(nonce)

                if (!nonce["data"]["NONCE"]) {
                    returnErrorMessage("Nonce could not be retrieved")
                }

                // Sign the string message
                let signer = library.getSigner()
                let signature = await signer.signMessage(nonce["data"]["NONCE"])

                let resp = await axios.post(APIADDRESS + "/api/verify", {
                    address: account,
                    signature: signature
                })

                if (!resp["data"]["TOKEN"]) {
                    returnErrorMessage("Token could not be retrieved")
                }

                const res = await logInUserCustom(resp["data"]["TOKEN"]);
                if (res.user !== null && res.user.uid !== null) {
                    await registerHandle(handle, res.user.uid);
                }
            } catch (e) {
                console.log('error');
                toggleisduplicate(true);
                console.log(e);

                setRegisterError1(returnErrorMessage(e.code));
            }
        } else {
            setRegisterError1(returnErrorMessage('reg/na'));
        }
    }

    async function GoogleSignUp(handle: string, setRegisterError1: Function) {
        toggleisduplicate(false);
        const resavailable = await isHandleAvailable(handle);

        if (resavailable) {
            try {
                const res = await logInUserFromGoogle();
                if (res.user !== null && res.user.uid !== null) {
                    await registerHandle(handle, res.user.uid);
                }
            } catch (e) {
                console.log('error');
                toggleisduplicate(true);
                console.log(e);

                setRegisterError1(returnErrorMessage(e.code));
            }
        } else {
            setRegisterError1(returnErrorMessage('reg/na'));
        }
    }

    async function registerUserAndRegisterHandle(
        email: string,
        password: string,
        handle: string,
        setRegisterError2: Function,
    ) {
        toggleisduplicate(false);
        const resavailable = await isHandleAvailable(handle);

        if (resavailable) {
            try {
                const res = await registerUser(email, password);

                if (res.user !== null && res.user.uid !== null) {
                    await registerHandle(handle, res.user.uid);
                }
            } catch (e) {
                console.log(e.code);

                setRegisterError2(returnErrorMessage(e.code));
            }
        } else {
            setRegisterError2(returnErrorMessage('reg/na'));
        }
    }

    async function signInMetamask() {
        let message = "Sign In To addrs";

        // Sign the string message
        let signer = library.getSigner()
        let signature = await signer.signMessage(message)

        try {
            await logInUserCustom(signature);
        } catch (e) {
            setLogInError(returnErrorMessage(e.code));
        }

        console.log("logged")
    }

    async function signMessage() {
        let message = "Hello World";
        console.log(1233)

// Sign the string message
        let signer = library.getSigner()
        let signature = await signer.signMessage(message)
        console.log(signature)


        const signerAddress = ethers.utils.verifyMessage(message, signature);

        console.log(signerAddress)
    }

    return (
        <> <Box width="100%" padding="32px" margin="auto">
            <Card width="100%" margin="auto">


                <RegisterModalFull
                    isOpen={isRegisterModalOpen}
                    onRegister={(email: string, password: string, handle: string) => registerUserAndRegisterHandle(email, password, handle, setRegisterError)}
                    onGoogleSignIn={(handle: string) => GoogleSignUp(handle, setRegisterError)}
                    onMetamaskRegister={(handle: string) => MetamaskSignUp(handle, setRegisterError)}
                    toggle={() => toggleRegisterModal(!isRegisterModalOpen)}
                    error={registerError}
                />
                <LogInModal
                    isOpen={isLogInModalOpen}
                    toggle={() => toggleLogInModal(!isLogInModalOpen)}
                    onLogIn={(email: string, password: string) => logInUserFromModal(email, password, setLogInError)}
                    onGoogleSignIn={() => GoogleSignIn(setLogInError)}
                    onMetamaskLogIn={() => MetamaskLogin(setLogInError)}
                    toggleLostPassword={() => toggleLostPasswordModal(!isLostPasswordModalOpen)}
                    error={logInError}
                />
                <LostPasswordModal
                    isOpen={isLostPasswordModalOpen}
                    toggle={() => toggleLostPasswordModal(!isLostPasswordModalOpen)}
                    onPasswordReset={(email: string) => resetPassword(email)}
                />
                <Row width="80%" padding="10px 0" margin="auto">
                    <BigTitle  noMargin>
                    Welcome to addrs
                    </BigTitle>
                </Row>

                <Row width="80%" padding="0 0 10px 0" margin="auto">
                    <SubTitle noMargin centered>
                        Send and Receive Crypto Without Addresses
                    </SubTitle>
                </Row>
                <Box padding="20px 20px" margin="auto">
                    <SearchFormManager/>
                </Box>
                <Row width="80%" padding="10px 0" margin="auto">
                    <Button
                        genre="primary"
                        onClick={() => toggleLogInModal(!isLogInModalOpen)}
                    >
                        LOG IN
                    </Button>
                </Row>                <Row width="80%" padding="0px 0" margin="auto">
                <a href={`/pay`}>

                <Button genre="primary">
            
              Create a pay request
               

          </Button>              </a>
 </Row>
                <WalletModal/>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Card>
        </Box>
            <Grid container spacing={1} margin="auto"style={{ textAlign: 'center' }}>
            <Grid xs={1} lg={1}></Grid><Grid xs={12} lg={5}>
                <Row width="80%" padding="10px 0" margin="auto">
                    <BigTitle style={{ textAlign: 'center' }}noMargin>
                    Free profile for ALL your crypto addresses.
                    </BigTitle><SubTitle noMargin>
                    We support as many assets as possible! From BTC to ETH to Tokens to privacy tokens, whatever you need we have it, and if we dont just ask!</SubTitle>
                </Row><Row margin="auto"> <img style={{ maxWidth: '90%', margin: 'auto' }} src='./im.png'></img>
                </Row></Grid><Grid xs={12} lg={5}><Row width="80%" padding="10px 0" margin="auto">
                    <BigTitle noMargin>
                    Non-Custodial
                    </BigTitle>
                    <SubTitle noMargin>
                    Your Crypto, your keys! All your assets are directly sent to your wallets! Supports QR and all mobile wallets.
                    </SubTitle>
                </Row><Row margin="auto"><img style={{ maxWidth: '90%', margin: 'auto' }} src='./image.png'></img>
                </Row></Grid><Grid xs={1} lg={1}></Grid>
            </Grid>
            
        </>
    );
}

export default Home;
