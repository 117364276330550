import React from 'react';
import {
  Box,
} from 'reflexbox';

import {
  Title,
  Text,
Link,
  Row,
  Card,Button
} from '../../style/components';
let ts =`<style>
/* Style for the button */
.btn {
    padding: 10px 15px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
}

/* Styles for the popup */
.popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

/* Styles for the overlay */
.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
</style>

<button class="btn" onclick="showPopup()">Pay with Crypto</button>
//place this in the body where you want the button to be remember to updaet the URL with your addrs profile, using addrs.to/myaddrs/tokenhere, you can also attach and optional preset amount using ?amount=1234amounthere to have preset amount requests.
<!-- Overlay -->
<div class="overlay" onclick="hidePopup()"></div>

<!-- Iframe Popup -->
<div class="popup">
<iframe id="scaledIframe" src="https://embed.addrs.to/pr0/eth" width="400" height="800"></iframe>
</div>

<script>
function showPopup() {
const iframe = document.querySelector('.popup iframe');

// Set the width of the iframe to the minimum of its predefined width or the screen's width
iframe.style.width = Math.min(600, window.innerWidth) + "px";

document.querySelector('.popup').style.display = 'block';
document.querySelector('.overlay').style.display = 'block';
}

function hidePopup() {
document.querySelector('.popup').style.display = 'none';
document.querySelector('.overlay').style.display = 'none';
}
</script>`
function About() {
  return (
    <Row width="80%" margin="auto"> <Card>
      <Box width={1}>
       
        <Title style={{ textAlign: 'center' }}>
          Welcome to addrs
        </Title>
      </Box>
      <Box width={1}>
        <Text>
        
 addrs is designed to revolutionize the way people send and receive cryptocurrency. We understand that one of the main barriers to wider adoption of cryptocurrency is the complexity and inconvenience of using long, complicated addresses. That s why we ve developed a platform that allows users to create payment profiles that function like a Linktree, containing all of their cryptocurrency addresses in one place. <br/>
<h3>What is addrs?</h3>
    <p>addrs is a platform that allows users to create payment profiles containing all of their cryptocurrency addresses in one place, making it easier to send and receive cryptocurrency.</p>
    <h3>How does addrs work?</h3>
    <p>With addrs, you can create a payment profile link that contains all of your cryptocurrency addresses. When someone clicks on the link, their wallet will open with your address automatically entered, eliminating the need to manually input long addresses.</p>
    <h3>Is addrs compatible with all wallets?</h3>
    <p>addrs is compatible with any BTC/ETH wallet that follows BIP20 for Bitcoin or EIP 67/681 for Ethereum, as well as other protocols, making it widely compatible with different wallets.</p>
    <h3>Does addrs hold onto my cryptocurrency or private keys?</h3>
    <p>No, addrs is a non-custodial platform, meaning that we do not hold onto your cryptocurrency or private keys. This gives users full control over their assets and adds an extra layer of security.</p>
    <h3>What other features does addrs offer?</h3>
    <p>In addition to the convenient link-based system for sending and receiving cryptocurrency, addrs also offers the ability to receive crypto using a Visa/MC/Bank and have it deposited directly into their wallet without the need for a custodial account.</p>
    <h3>How can you use addrs in your site?</h3>
    <p>You can easily embed addrs directly into your site using our optimized embed.addrs.to embeds. Just plug and play and itll handle the rest. Remember to update pr0 with your addrs. Use:</p>
    <p>&lt;iframe src=&quot;https://embed.addrs.to/pr0/eth&quot;/&gt;</p>
    <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}}>< Button genre='primary'  style={{
  marginRight: '6px'
}}onClick={() => navigator.clipboard.writeText('<iframe src="https://embed.addrs.to/pr0/eth"/>')}>Copy simple embed</Button>    < Button onClick={() => navigator.clipboard.writeText(ts)}genre="primary">Copy embed button</Button>
</div>
</Text>
      </Box>
      <Row>
<Link href="mailto:hey@addrs.to" target="_blank" rel="noopener noreferrer" margin="0 20px" navLink>
          Contact us
        </Link></Row>
<br></br><Row>
<Link href="https://keychain.me" target="_blank" rel="noopener noreferrer" margin="0 20px" navLink>
          Check out our other projects
        </Link></Row>
        <br></br><Row>
<Link href="https://discord.gg/ZV3R9Wc4wf" target="_blank" rel="noopener noreferrer" margin="0 20px" navLink>
          Community
        </Link>
        </Row>
        </Card>
    </Row>
  );
}

export default About;
