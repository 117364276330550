import React from 'react';
import ReactDOM from 'react-dom';


import * as firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';

import firebaseConfig from './config/firebaseConfig';

import App from './components/app';
import ReactGA from "react-ga4";

const TRACKING_ID = "G-P48R7L5DV1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
