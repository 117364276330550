const firebaseConfig = {
  apiKey: 'AIzaSyCyLx0LG3lPMGkuHq0-Da9s2uUTU4SqG1g',
  authDomain: 'handles-b3952.firebaseapp.com',
  databaseURL: 'https://handles-b3952.firebaseio.com',
  projectId: 'handles-b3952',
  storageBucket: 'handles-b3952.appspot.com',
  messagingSenderId: '532243888409',
  appId: '1:532243888409:web:b9fa4f7269de89ec7bad88',
  measurementId: 'G-P76GXY8LP5',
};

export default firebaseConfig;
