import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '../index';
import ChevronDown from '../icons/chevronDown';

interface DropdownInterface {
  block?: boolean;
  coins: CoinsInterface;
  initialLabel: string | number;
  onSelect: (token: string | number) => void;
}

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownList = styled.div<{ isOpen: boolean }>`
  z-index: 10;
  transform-origin: 50% 0px;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: all 0.2s ease-in-out 0s;
  position: absolute;
  left: -120px;
  transform: ${(props) => (props.isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  border: none;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: ${(props) => props.theme.border.radius};
  padding: 8px;
  box-sizing: border-box;
  width: 400px;
  max-height: 250px;
  overflow: auto;  display: grid;
  grid-template-columns: repeat(3, 1fr); // Example: 3 columns
  gap: 10px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Option = styled.div<{ isSelected: boolean }>`
  font-family: ${(props) => props.theme.font.family};
  font-size: 14.5px;
  font-weight: ${(props) => props.theme.font.weight.semiBold};
  color: ${(props) =>
    props.isSelected ? props.theme.colors.brand : props.theme.colors.primary};
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  padding: 4px 16px;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.brand};
  }
`;

const Logo = styled.img`
  height: 26px;
  vertical-align: middle;
  margin: 0px 6px;
  border-radius: 50%;
`;

const SearchInput = styled.input`
  font-family: ${(props) => props.theme.font.family};
  font-size: 14.5px;
  color: ${(props) => props.theme.colors.primary};
  box-sizing: border-box;
  width: 100%;
  padding: 8px 16px;
  border: none;
  left: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
  outline: none;
  margin-bottom: 8px;
  margin-left: 16px;

  border-color: #c1e1ff
`;

const ClearButton = styled.button`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
`;

function Dropdown(props: DropdownInterface) {
  const { block, coins, initialLabel, onSelect } = props;
  const [isOpen, toggle] = useState(false);
  const [currentValue, setCurrentValue] = useState<string | number>(initialLabel);
  const [searchValue, setSearchValue] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const filteredCoins = Object.keys(coins).filter((token) =>
    token.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleOptionClick = useCallback(
    (token: string) => {
      setCurrentValue(token);
      toggle(false);
      onSelect(token);
    },
    [onSelect]
  );

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        toggle(false);
      }
    },
    [dropdownRef]
  );

  const handleClear = useCallback(() => {
    setCurrentValue(initialLabel);
    onSelect(initialLabel);
  }, [initialLabel, onSelect]);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <DropdownWrapper ref={dropdownRef}>
      <Button
        genre="primary"
        onClick={() => toggle(!isOpen)}
        block={block}
        padding="8px 12px"
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {typeof currentValue === 'string' && currentValue !== initialLabel && (
          <Logo alt={currentValue} src={coins[currentValue].logo} />
        )}
        {currentValue}
        {typeof currentValue === 'string' && currentValue !== initialLabel && (
          <ClearButton onClick={handleClear}>
          </ClearButton>
        )}{' '}
        <ChevronDown white />
      </Button>
      <DropdownList isOpen={isOpen} aria-label="dropdown-list">
        <SearchInput
          type="text"
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {filteredCoins.map((token) => (
          <Option
            key={token}
            role="button"
            tabIndex={0}
            isSelected={currentValue === token}
            onClick={() => handleOptionClick(token)}
            onKeyPress={() => handleOptionClick(token)}
          >
            <Logo alt={token} src={coins[token].logo} />
            <br />
            {token}
          </Option>
        ))}
      </DropdownList>
    </DropdownWrapper>
  );
}

export default Dropdown;